<div class="h-full w-full flex items-center">
  <div class="flex justify-center w-full py-8">
    <div
      class="bg-white shadow-md rounded-lg px-6 py-8 w-4/5 md:1/2 max-w-lg md:p-14 flex flex-col items-center relative">
      @if (inDrawer()) {
        <button type="button"
                (click)="drawerService.close()"
                class="absolute top-1 right-1 inline-flex rounded-md p-1.5 text-gray-500 hover:text-100ciels-300 focus:outline-none focus:ring-1 focus:ring-100ciels-300 focus:ring-offset-2 focus:ring-offset-100ciels-100">
          <span class="sr-only">Dismiss</span>
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
          </svg>
        </button>
      }
      <div class="flex justify-between">
        <h1 class="text-2xl font-bold uppercase text-gray-500">
          {{ 'forgotPassword' | translate }}
        </h1>
      </div>

      <form [formGroup]="form" class="w-full flex-1 mt-4 flex flex-col items-center">
        <div class="w-full">
          <p class="font-medium text-gray-500 text-sm mt-3">{{ 'email' | translate }}</p>

          <input
            class="mt-2 w-full px-8 py-4 rounded-lg border-gray-200 font-medium bg-white text-sm focus:outline-none"
            formControlName="email"
            type="email"/>

          <div class="md:flex justify-end mt-4">
            <p class="text-xs text-gray-600 mt-4 md:mt-0">
              <button
                (click)="drawerService.switch({component: SignInComponent, inputs: { inDrawer: true }, style: 'transparent'})"
                (keyup.enter)="emulateClick($event)"
                class="border-b border-gray-500 border-solid font-semibold"
                type="button">
                {{ 'signIn' | translate }}
              </button>
            </p>
          </div>

          <!--Big Button-->
          <button [appProgressClick]="resetPassword"
                  [disabled]="form.invalid"
                  appHoverGradient
                  class="big-btn py-4 text-md"
                  type="submit">
            {{ 'auth.resetPassword' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
