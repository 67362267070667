import { NgIf } from '@angular/common';
import {
  Component,
  Input,
} from '@angular/core';
import { NgxTolgeeModule } from '@tolgee/ngx';

import { Error } from '../../../core/error';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [ NgIf, NgxTolgeeModule ],
  template: `
    @if (error) {
      <div style="background-color: red">
        {{ error.message }}
        @if (errorAction) {
          <button (click)="errorAction.action()">{{ errorAction.label | translate }}</button>
        }
      </div>
    }
  `,
})
/**
 * Display an error
 */
export class ErrorComponent {
  @Input() error: Error | null = null;
  @Input() errorAction: {
    action: () => unknown,
    label: string
  } | null = null;
}
