<div
  class="relative mx-auto block w-full md:max-w-lg rounded-lg bg-white px-8 py-8 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">

  <button (click)="drawerService.close()"
          class="absolute top-1 right-1 inline-flex rounded-md p-1.5 text-gray-500 hover:text-100ciels-300 focus:outline-none focus:ring-1 focus:ring-100ciels-300 focus:ring-offset-2 focus:ring-offset-100ciels-100"
          type="button">
    <span class="sr-only">Dismiss</span>
    <svg aria-hidden="true" class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
      <path
        d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
    </svg>
  </button>
  <!--Center title -->
  <h1 class="font-bold text-3xl md:text-4xl leading-tight text-100ciels-300 uppercase text-center">
    {{ 'confirmSignInEmail.title' | translate }}
  </h1>
  <!-- Description -->
  <p [innerHtml]="('confirmSignInEmail.description' | translate : { idp: idp() }) | byPass"
     class="my-4 text-gray-500 font-semibold text-start whitespace-pre-line">
  </p>

  <!--Big Button-->
  <div class="flex flex-col md:flex-row gap-2 md:gap-8">
    <button
      (click)="drawerService.switch({ component: SignInComponent, inputs: { inDrawer: true }, style: 'transparent' })"
      (keyup.enter)="emulateClick($event)"
      class="big-btn main-btn bg-gray-500 hover:bg-gray-400">
      {{ 'cancel' | translate }}
    </button>
    <button [appProgressClick]="signIn" appHoverGradient class="big-btn">
      {{ 'signIn' | translate }}
    </button>
  </div>
</div>
