import { CommonModule } from '@angular/common';
import {
  Component,
  inject,
  input,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  Router,
  RouterLink
} from '@angular/router';
import { NgxTolgeeModule } from '@tolgee/ngx';

import { emulateClick } from '../../core/a11y';
import { Error } from '../../core/error';
import { DrawerService } from '../../drawer/drawer.service';
import { NotificationService } from '../../notification/notification.service';
import { ErrorComponent } from '../../shared/components/error/error.component';
import { HoverGradientDirective } from '../../shared/directives/hover-gradient.directive';
import { ProgressClickDirective } from '../../shared/directives/progress-click.directive';
import { AuthService } from '../../shared/services/auth.service';
import { EmailValidator } from '../../shared/validators/validators';
import { SignInComponent } from '../sign-in/sign-in.component';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [ CommonModule, ErrorComponent, ReactiveFormsModule, NgxTolgeeModule, HoverGradientDirective, RouterLink,
    ProgressClickDirective ],
  templateUrl: './reset-password.component.html',
})
/**
 * Component to sign in, sign up or reset password
 */
export class ResetPasswordComponent {
  readonly emulateClick = emulateClick;
  readonly SignInComponent = SignInComponent;
  drawerService = inject(DrawerService);
  inDrawer = input(false);
  form: FormGroup<{ email: FormControl<string> }> = new FormGroup({
    email: new FormControl<string>('', { nonNullable: true, validators: [ Validators.required, EmailValidator ] })
  });
  private router = inject(Router);
  private authService = inject(AuthService);
  private notificationService = inject(NotificationService);

  constructor() {
    if (this.authService.user()?.authCompleted) {
      this.router.navigate([ '/' ], { replaceUrl: true }).catch(err => console.error(err))
    }
  }

  /**
   * Email the user to reset the password
   * @returns The promise
   */
  resetPassword = async (): Promise<void> => {
    const formData = this.form.getRawValue();
    try {
      await this.authService.resetPassword(formData.email);
      this.notificationService.open({ type: 'message', title: 'auth.resetPassword', message: 'checkInbox' });
      this.drawerService.close();
    } catch (err) {
      const error = err as Error;
      this.notificationService.open({ type: 'error', title: error.details.code, message: error.message });
    }
  }
}
