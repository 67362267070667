import { CommonModule } from '@angular/common';
import {
  Component,
  inject,
  input,
  OnInit,
  signal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  Router,
  RouterLink
} from '@angular/router';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { ConfirmSignInEmailComponent } from '../../confirm-sign-in-email/confirm-sign-in-email.component';
import { emulateClick } from '../../core/a11y';
import { Error } from '../../core/error';
import { DrawerService } from '../../drawer/drawer.service';
import {
  type Notification,
  NotificationService
} from '../../notification/notification.service';
import { ErrorComponent } from '../../shared/components/error/error.component';
import { HoverGradientDirective } from '../../shared/directives/hover-gradient.directive';
import { ProgressClickDirective } from '../../shared/directives/progress-click.directive';
import { ByPassPipe } from '../../shared/pipes/by-pass.pipe';
import { AuthService } from '../../shared/services/auth.service';
import { CartService } from '../../shared/services/cart.service';
import {
  EmailValidator,
  PasswordValidator
} from '../../shared/validators/validators';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    CommonModule,
    ErrorComponent,
    ReactiveFormsModule,
    NgxTolgeeModule,
    HoverGradientDirective,
    RouterLink,
    ProgressClickDirective,
    ByPassPipe
  ],
  templateUrl: './sign-in.component.html',
})
/**
 * Component to sign in, sign up or reset password
 */
export class SignInComponent implements OnInit {
  drawerService = inject(DrawerService);
  readonly emulateClick = emulateClick;
  inDrawer = input(false);
  login = input('');
  password = input('');
  autoLogin = input(false);
  showPassword = signal<boolean>(false);
  form: FormGroup<{
    email: FormControl<string>,
    password: FormControl<string>
  }> = new FormGroup({
      email: new FormControl<string>('',
        { nonNullable: true, validators: [ Validators.required, EmailValidator ] }),
      password: new FormControl<string>(
        '',
        {
          nonNullable: true,
          validators: [ Validators.required, PasswordValidator ],
        }),
    });
  private cartService = inject(CartService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private notificationService = inject(NotificationService);

  constructor() {
    if (this.authService.user()?.authCompleted) {
      this.router.navigate([ '/' ], { replaceUrl: true }).catch(err => console.error(err))
    }
  }

  ngOnInit(): void {
    // Used after a login/password sign-up flow
    if (this.autoLogin()) {
      this.form.controls.email.setValue(this.login());
      this.form.controls.password.setValue(this.password());
    }
  }

  /**
   * Sign in the user
   * @returns The promise
   */
  signIn = async (): Promise<void> => {
    const formData = this.form.getRawValue();
    try {
      if (this.cartService.cart().items.length && this.authService.user()?.uid) {
        const uid = this.authService.user()?.uid;
        if (uid) {
          localStorage.setItem('transferCart', uid);
        }
      }
      const created = await this.authService.signIn(formData.email, formData.password);
      if (created) {
        this.notificationService.open({ type: 'message', title: 'verifyEmail', message: 'checkInbox' });
      } else {
        await this.afterSigInIn();
      }
    } catch (err) {
      const error = err as Error;
      const notification: Notification = {
        type: 'error',
        title: error.details.code,
        message: error.message
      };
      if (error.details.code === 'auth/email-not-verified') {
        notification.type = 'warning';
        notification.message = 'auth.emailNotVerified';
        notification.button = {
          action: () => this.authService.sendConfirmEmail(formData.email),
          label: 'auth.resendEmail',
        };
      }
      if (error.details.code === 'auth/invalid-credential') {
        notification.type = 'warning';
        notification.message = 'auth.userNotFound';
      }
      this.notificationService.open(notification);
    }
  }

  async signInWithIdp(idp: 'Google' | 'Facebook' | 'Apple'): Promise<void> {
    if (this.cartService.cart().items.length && this.authService.user()?.uid) {
      const uid = this.authService.user()?.uid;
      if (uid) {
        localStorage.setItem('transferCart', uid);
      }
    }
    this.drawerService.switch({ component: ConfirmSignInEmailComponent, style: 'transparent', inputs: { idp } })
  }

  /**
   * Email the user to reset the password
   * @returns The promise
   */
  async resetPassword(): Promise<void> {
    this.drawerService.switch({
      component: ResetPasswordComponent,
      inputs: { inDrawer: true },
      style: 'transparent'
    });
  }

  /**
   * Action after user signed in
   */
  private async afterSigInIn(): Promise<void> {
    this.drawerService.close(true);
    if (this.router.url.includes('account/sign-in')) {
      await this.router.navigate([ '/' ], { replaceUrl: true });
    }
  }
}
