import {
  Component,
  inject,
  input
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { SignInComponent } from '../account/sign-in/sign-in.component';
import { emulateClick } from '../core/a11y';
import { DrawerService } from '../drawer/drawer.service';
import { HoverGradientDirective } from '../shared/directives/hover-gradient.directive';
import { ProgressClickDirective } from '../shared/directives/progress-click.directive';
import { ByPassPipe } from '../shared/pipes/by-pass.pipe';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-confirm-sign-in-email',
  standalone: true,
  imports: [
    HoverGradientDirective,
    ProgressClickDirective,
    ByPassPipe,
    NgxTolgeeModule
  ],
  templateUrl: './confirm-sign-in-email.component.html'
})
export class ConfirmSignInEmailComponent {
  readonly emulateClick = emulateClick;
  router = inject(Router);
  drawerService = inject(DrawerService);
  authService = inject(AuthService);
  idp = input.required<'Google' | 'Facebook' | 'Apple'>();
  protected readonly SignInComponent = SignInComponent;

  signIn: () => Promise<void> = async () => {
    switch (this.idp()) {
    case 'Google':
      return this.signInWithGoogle();
    case 'Facebook':
      return this.signInWithFacebook();
    case 'Apple':
      return this.signInWithApple();
    }
  };

  async signInWithGoogle(): Promise<void> {
    await this.authService.signInWithGoogle()
    await this.afterSigInIn();
  }

  async signInWithFacebook(): Promise<void> {
    await this.authService.signInWithFacebook();
    await this.afterSigInIn();
  }

  async signInWithApple(): Promise<void> {
    await this.authService.signInWithApple();
    await this.afterSigInIn();
  }

  /**
   * Action after user signed in
   */
  private async afterSigInIn(): Promise<void> {
    this.drawerService.close(true);
    if (this.router.url.includes('account/sign-in')) {
      await this.router.navigate([ '/' ], { replaceUrl: true });
    }
  }
}
